import { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { triggerDownloadBlob } from '../../utils/download';
import { MarketplaceReportLines } from '../../components/MarketplaceReportLines';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { NotificationContext } from '../../context/NotificationContext';
import * as apiCalls from '../../logic/apiCalls';

import sharedCss from '../Shared.module.scss';
import css from '../../components/MarketplaceReport.module.scss';

const paymentHistoryFileLabel = "Choose payment history file.";
const transactionHistoryFileLabel = "Choose transaction history file.";

class MarketplaceReportInternal extends Component {

    state = {
        errorMessage: "",
        report: [],
        showEnabled: true,
        showDisabled: true,
        showMonthly: true,
        showYearly: true,
        showTwoYearly: true,
        showThreeYearly: true,
        showRecordedOnly: false,
        showTrial: true,
        showDetails: false,
        showOverdueOnly: false,
        markDifferences: false,
        isUploadFilesModalOpen: false,
        paymentHistoryFile: {name: paymentHistoryFileLabel},
        transactionHistoryFile: {name: transactionHistoryFileLabel},
    }

    async componentDidMount() {
        await this.generateReport();
    }

    generateReport = async () => {
        console.log("Start generating report...");

        try {
            console.log("[generateReport] Invoking the back-end API to get the subscriptions report.");

            const report = await apiCalls.getMarketplaceReport(this.props.authenticationContext);
            this.setState({
                report: report
            });
        }
        catch (err) {
            console.log(`[generateReport] Error retrieving marketplace report: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    executeUploadFiles = async () => {
        let requests = [];

        if (this.state.paymentHistoryFile.name !== paymentHistoryFileLabel)
        {
            const data = new FormData()
            data.append('file', this.state.paymentHistoryFile)

            requests.push(apiCalls.updateMicrosoftPaymentHistoryFile(this.props.authenticationContext, data));
        }

        if (this.state.transactionHistoryFile.name !== transactionHistoryFileLabel)
        {
            const data = new FormData()
            data.append('file', this.state.transactionHistoryFile)

            requests.push(apiCalls.updateMicrosoftTransactionHistoryFile(this.props.authenticationContext, data));           
        }

        if (requests.length > 0)
        {
            try {
                await Promise.all(requests);
             }
            catch (err) {
                this.props.notificationContext.setNotification("Uploading files", `Error uploading files ('${this.state.paymentHistoryFile}', '${this.state.transactionHistoryFile}').  ${err.toDetailedMessage()}`, 'danger');
            }
            this.hideUploadFilesModal();
            await this.generateReport();
        }
    }

    exportMarketplaceReportClick = async () => {
        try {
            console.log("[exportMarketplaceReport] Invoking the back-end API to export the marketplace report in CSV format.");

            const marketplaceReportExport = await apiCalls.getMarketplaceReportCsv(this.props.authenticationContext);

            triggerDownloadBlob(marketplaceReportExport, 'MarketplaceReport.csv');
        }
        catch (err) {
            console.log(`[exportMarketplaceReportClick] Error exporting the marketplace report: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    handleShowEnabledClick = () => {
        this.setState((prevState) => {
            return {
                showEnabled: !prevState.showEnabled,
                showDisabled: prevState.showDisabled || (prevState.showEnabled && !prevState.showDisabled)
            }
        });
    }
    handleShowDisabledClick = () => {
        this.setState((prevState) => {
            return {
                showEnabled: prevState.showEnabled || (!prevState.showEnabled && prevState.showDisabled),
                showDisabled: !prevState.showDisabled
            }
        });
    }
    handleShowMonthlyClick = () => {
        this.setState((prevState) => {
            return {
                showMonthly: !prevState.showMonthly,
                showYearly: prevState.showYearly || (prevState.showMonthly && !prevState.showYearly && !prevState.showTwoYearly && !prevState.showThreeYearly)
            }
        });
    }
    handleShowYearlyClick = () => {
        this.setState((prevState) => {
            return {
                showMonthly: prevState.showMonthly || (!prevState.showMonthly && prevState.showYearly && !prevState.showTwoYearly && !prevState.showThreeYearly),
                showYearly: !prevState.showYearly
            }
        });
    }
    handleShowTwoYearlyClick = () => {
        this.setState((prevState) => {
            return {
                showMonthly: prevState.showMonthly || (!prevState.showMonthly && !prevState.showYearly && prevState.showTwoYearly && !prevState.showThreeYearly),
                showTwoYearly: !prevState.showTwoYearly
            }
        });
    }
    handleShowThreeYearlyClick = () => {
        this.setState((prevState) => {
            return {
                showMonthly: prevState.showMonthly || (!prevState.showMonthly && !prevState.showYearly && !prevState.showTwoYearly && prevState.showThreeYearly),
                showThreeYearly: !prevState.showThreeYearly
            }
        });
    }
    handleShowRecordedClick = () => {
        this.setState((prevState) => {
            return {
                showRecordedOnly: !prevState.showRecordedOnly
            }
        });
    }
    handleShowTrialClick = () => {
        this.setState((prevState) => {
            return {
                showTrial: !prevState.showTrial
            }
        });
    }
    handleShowDetailsClick = () => {
        this.setState((prevState) => {
            return {
                showDetails: !prevState.showDetails
            }
        });
    }
    handleShowOverDueOnlyClick = () => {
        this.setState((prevState) => {
            return {
                showOverdueOnly: !prevState.showOverdueOnly
            }
        });
    }
    handleMarkDifferencesClick = () => {
        this.setState((prevState) => {
            return {
                markDifferences: !prevState.markDifferences
            }
        });
    }

    showUploadFilesModal = () => {
        this.setState(() => {
            return {
                isUploadFilesModalOpen: true
            }
        }); 
    }

    hideUploadFilesModal = () => {
        this.setState(() => {
            return {
                isUploadFilesModalOpen: false,
                paymentHistoryFile: {name: paymentHistoryFileLabel},
                transactionHistoryFile: {name: transactionHistoryFileLabel},
            }
        }); 
    }

    onPaymentHistoryFileChange = (file) => {
        this.setState(() => {
            return {
                paymentHistoryFile: file
            }
        });        
    }

    onTransactionHistoryFileChange = (file) => {
        this.setState(() => {
            return {
                transactionHistoryFile: file
            }
        });        
    }

    render() {
        let allLinesShown = false;
        let reportLines = []
        if (this.state.report.lines) {
            reportLines = this.state.report.lines.filter((item) => {
                const isTrial = !item.months.some(month => month.expectedPayment > 0);

                if (this.state.showRecordedOnly && !item.recordingEnabled)
                    return false
                if (this.state.showOverdueOnly && !item.paymentOverdue)
                    return false
                if ( ( (this.state.showEnabled && item.enabled) || (this.state.showDisabled && !item.enabled) ) &&
                    ( (this.state.showMonthly && item.paymentPeriod.toLowerCase() === "monthly") ||
                    (this.state.showYearly && item.paymentPeriod.toLowerCase() === "yearly")  ||
                    (this.state.showTwoYearly && item.paymentPeriod.toLowerCase() === "twoyearly")  ||
                    (this.state.showThreeYearly && item.paymentPeriod.toLowerCase() === "threeyearly") ) &&
                    ( (this.state.showTrial && isTrial) || !isTrial ) )
                    return true;
                return false;
            });

            if (this.state.report.lines.length === reportLines.length)
            {
                allLinesShown = true;
            }
        }
    
        let totalMonthly = 0;
        let totalYearly = 0;
        let totalTwoYearly = 0;
        let totalThreeYearly = 0;
        let totalQuantity = 0;
        let monthPayments = [];
        let totalRecorded = 0;
        if (reportLines.length > 0) {
            for (var reportLine of reportLines) {
                if (reportLine.enabled)
                {
                    totalQuantity += reportLine.quantity;
                }

                let monthIndex = 0;
                if (reportLine.months.length !== this.state.report.months.length)
                {
                    continue;
                }

                for (var month of reportLine.months) {
                    // Create object when not jet exists for index.
                    if (!monthPayments[monthIndex])
                    {
                        monthPayments[monthIndex] = {};
                    }

                    if (monthPayments[monthIndex].expected)
                    {
                        monthPayments[monthIndex].expected += month.expectedPayment;
                    }
                    else
                    {
                        monthPayments[monthIndex].expected = month.expectedPayment;
                    }

                    // Only calculate totals for past months and the present month.
                    if (month.date <= this.state.report.lastTransaction)
                    {
                        if (monthPayments[monthIndex].actual)
                        {
                            monthPayments[monthIndex].actual += month.actualPayment;
                        }
                        else
                        {
                            monthPayments[monthIndex].actual = month.actualPayment;
                        }

                        const missingPayment = month.expectedPayment > 0 && 
                            (month.actualPayment <= 0 ||
                            month.actualPayment < 0.75 * month.expectedPayment);
                        const extraPayment = month.actualPayment !== 0 && 
                            (month.expectedPayment <= 0 ||
                            month.expectedPayment < 0.75 * month.actualPayment);

                        if (missingPayment || extraPayment)
                        {
                            if (monthPayments[monthIndex].difference)
                            {
                                monthPayments[monthIndex].difference += month.expectedPayment - month.actualPayment;
                            }
                            else
                            {
                                monthPayments[monthIndex].difference = month.expectedPayment - month.actualPayment;
                            }
                        }
                    }

                    monthIndex++
                }
                

                if (reportLine.recordingEnabled) {
                    totalRecorded++;
                }
                
                if (reportLine.paymentPeriod.toLowerCase() === "monthly") {
                    totalMonthly++;
                }
                
                if (reportLine.paymentPeriod.toLowerCase() === "yearly") {
                    totalYearly++;
                }
                
                if (reportLine.paymentPeriod.toLowerCase() === "twoyearly") {
                    totalTwoYearly++;
                }
                
                if (reportLine.paymentPeriod.toLowerCase() === "threeyearly") {
                    totalThreeYearly++;
                }
            }
        }

        const uploadFilesModalRendered = <>
            <Modal style={{ opacity: 1 }} size="lg" show={this.state.isUploadFilesModalOpen} onHide={this.hideUploadFilesModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload transaction and/or payment history files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile" as={Row} >
                            <h6>Uploaded files may be switched (system has auto detect)</h6>
                        </Form.Group>
                        <Form.Group controlId="formFile2" as={Row} >
                            <Form.File
                                type="file"
                                label={this.state.paymentHistoryFile.name}
                                onChange={
                                    (e) => this.onPaymentHistoryFileChange(e.target.files[0])
                                }
                                accept=".zip"
                                custom
                            />
                        </Form.Group>
                        <Form.Group controlId="formFile2" as={Row} >
                            <Form.File
                                type="file"
                                label={this.state.transactionHistoryFile.name}
                                onChange={
                                    (e) => this.onTransactionHistoryFileChange(e.target.files[0])
                                }
                                accept=".zip"
                                custom
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideUploadFilesModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.executeUploadFiles}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        // Sort the report lines on creation time.
        reportLines.sort((a,b) => (a.lastModifiedTime < b.lastModifiedTime) ? 1 : ((b.lastModifiedTime < a.lastModifiedTime) ? -1 : 0))
        
        return <Container fluid className={sharedCss.background}>
            {uploadFilesModalRendered}
            <div className="mb-3">
                <Button className={css.itemsInline} onClick={this.showUploadFilesModal} color="primary" >Upload new transaction and/or payment files</Button>
                <h6 className={css.itemsInline}>Last transaction on: {String(this.state.report.lastTransaction).split('T')[0]}</h6>
                <Link className="mt-1 float-right" to='/portal/distributor-settings'>
                    <Button color="primary">Close</Button>
                </Link>
            </div>
            <Form>
                <div className="mb-3 form-group row">
                    <div className="col-sm-1"><Button color="primary" onClick={this.exportMarketplaceReportClick}>Export to CSV</Button></div>   
                </div>
                <div className="mb-3 form-group row">
                    <div className="col"><h6>Filters:</h6></div>
                    <div className="col"><Form.Check id="showEnabledCheckbox" style={{zIndex: 0}} custom checked={this.state.showEnabled} type="checkbox" label="Active" onChange={this.handleShowEnabledClick} /></div>
                    <div className="col"><Form.Check id="showDisabledCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showDisabled} type="checkbox" label="Terminated" onChange={this.handleShowDisabledClick} /></div>
                    <div className="col"><Form.Check id="showMonthlyCheckbox" style={{zIndex: 0}} custom checked={this.state.showMonthly} type="checkbox" label="Monthly" onChange={this.handleShowMonthlyClick} /></div>
                    <div className="col"><Form.Check id="showYearlyCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showYearly} type="checkbox" label="Yearly" onChange={this.handleShowYearlyClick} /></div>
                    <div className="col"><Form.Check id="showTwoYearlyCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showTwoYearly} type="checkbox" label="Two Yearly" onChange={this.handleShowTwoYearlyClick} /></div>
                    <div className="col"><Form.Check id="showThreeYearlyCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showThreeYearly} type="checkbox" label="Three Yearly" onChange={this.handleShowThreeYearlyClick} /></div>
                    <div className="col"><Form.Check id="showRecordedCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showRecordedOnly} type="checkbox" label="Recorded Only" onChange={this.handleShowRecordedClick} /></div>
                    <div className="col"><Form.Check id="showTrialCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showTrial} type="checkbox" label="Trial Subscriptions" onChange={this.handleShowTrialClick} /></div>
                    <div className="col"><Form.Check id="showDetailsCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showDetails} type="checkbox" label="Details" onChange={this.handleShowDetailsClick} /></div>
                    <div className="col"><Form.Check id="showOverdueOnlyCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showOverdueOnly} type="checkbox" label="Overdue only" onChange={this.handleShowOverDueOnlyClick} /></div>
                    <div className="col"><Form.Check id="markDifferencesCheckbox" style={{ zIndex: 0 }} custom checked={this.state.markDifferences} type="checkbox" label="Differences" onChange={this.handleMarkDifferencesClick} /></div>
                </div>
            </Form>            
            <div style={{ overflow: 'auto' }}>
                <MarketplaceReportLines showDetails={this.state.showDetails} months={this.state.report.months || []} reportLines={reportLines || []} totalMonthly={totalMonthly} totalYearly={totalYearly} totalTwoYearly={totalTwoYearly} totalThreeYearly={totalThreeYearly} totalQuantity={totalQuantity} totalRecorded={totalRecorded} monthPayments={monthPayments} lastTransaction={this.state.report.lastTransaction} showEnabled={this.state.showEnabled} showDisabled={this.state.showDisabled} showRecordedOnly={this.state.showRecordedgOnly} markDifferences={this.state.markDifferences} allLinesShown={allLinesShown}/>
            </div>            
        </Container>;
    }
}

export const MarketplaceReport = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
  
    return (
        <MarketplaceReportInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}