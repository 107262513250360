import { Component } from 'react';
import { MarketplaceReportLine } from './MarketplaceReportLine'; 
import { MarketplaceTotalReportLine } from './MarketplaceTotalReportLine'; 

import css from './MarketplaceReport.module.scss';

export class MarketplaceReportLines extends Component {
    render() {
        const months = this.props.months;
        const reportLines = this.props.reportLines;
        const totalMonthly = this.props.totalMonthly;
        const totalYearly = this.props.totalYearly;
        const totalTwoYearly = this.props.totalTwoYearly;
        const totalThreeYearly = this.props.totalThreeYearly;
        const totalQuantity = this.props.totalQuantity;
        const totalRecorded = this.props.totalRecorded;
        const monthPayments = this.props.monthPayments;
        const lastTransaction = this.props.lastTransaction;
        const showDetails = this.props.showDetails;
        const markDifferences = this.props.markDifferences;
        const allLinesShown = this.props.allLinesShown;

        const detailColumns = showDetails ? 
            <>
                <th scope="col">Tenant ID</th>
                <th scope="col">Enabled</th>
                <th scope="col">State</th>
                <th scope="col">Auto Renew</th>
                <th scope="col">Free trial period</th>
                <th scope="col">E-mail</th>
                <th scope="col">Subscription</th>
                <th scope="col">Subscription ID</th>
            </>
            : null;

        return (
            <div className={css.tableFixHeadFoot}>
                <table className="table table-striped table-bordered my-2">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Tenant</th>
                            {detailColumns}
                            <th scope="col">Created</th>
                            <th scope="col">Payment</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Recorded</th>
                            {months.map((value, index) => {
                                return <th key={index} className={css.tableCellAlignCenter} scope="col">
                                        {value.month}
                                    </th>
                            })}
                        </tr>
                    </thead>
                    <tbody >
                        {reportLines.map((value, index) => {
                            return <MarketplaceReportLine showDetails={showDetails} key={index} reportLine={value} lastTransaction={lastTransaction} markDifferences={markDifferences}/>
                        })}
                    </tbody>
                    <tfoot>
                        <MarketplaceTotalReportLine key='Total' showDetails={showDetails} totalMonthly={totalMonthly} totalYearly={totalYearly} totalTwoYearly={totalTwoYearly} totalThreeYearly={totalThreeYearly} totalQuantity={totalQuantity} totalSubscriptions={reportLines.length} totalRecorded={totalRecorded} monthPayments={monthPayments} months={months} allLinesShown={allLinesShown} markDifferences={markDifferences}/>
                    </tfoot>
                </table>           
            </div>
        )
    }
}